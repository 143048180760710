import React from "react";
import sanitizeHtml from 'sanitize-html'
import PocketService from "../services/PocketService";

export default class FeedItem extends React.Component {
    constructor(props) {
        super(props)

        this.printDate = this.printDate.bind(this)
        this.handleClick = this.handleClick.bind(this)
    }

    printDate(dateString) {
        return (new Date(dateString)).toLocaleString('de-DE')
    }

    handleClick(e) {
        console.log("Adding to pocket")
        PocketService.addToPocket(this.props.item.id).then(response => {
            console.log(response)
            this.props.handleAddedToPocket(this.props.item.id)
        }).catch(error => {
            console.log(error)
        })
    }

    render() {
        return (
          <article className="feeditem  mb-8 relative">
            {this.props.item.status === "todo" && (
              <div className="feeditem-added shadow-lg ring-2 ring-green-900 bg-green-900/[0.4] absolute top-0 left-0 w-full h-full flex items-center justify-center">
                <p className="text-green-500">Added to Pocket.</p>
              </div>
            )}

            {this.props.item.status === "ignored" && (
              <div className="feeditem-ignored bg-mhgrey/[0.2] ring-2 shadow-lg ring-gray-900 absolute top-0 left-0 w-full h-full flex items-center justify-center">
                <p className="text-gray-500">Ignored</p>
              </div>
            )}

            <div
              className={`feeditem-item pareto-card shadow-lg hover:cursor-pointer ${
                this.props.item.status !== "unread" ? "invisible" : ""
              }`}
              onClick={this.handleClick}
            >
              <p className="text-gray-300 text-sm">
                {this.props.item.feed_title} -{" "}
                {this.printDate(this.props.item.date)}
              </p>
              <h2 className="text-mhorange">{this.props.item.title}</h2>
              <p
                className=""
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(this.props.item.desc),
                }}
              ></p>
            </div>
          </article>
        );
    }
}