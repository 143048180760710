import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Login from '../pages/Login'
import Register from '../pages/Register'
import Dashboard from '../pages/Dashboard'
import ForgotPassword from '../pages/ForgotPassword'
import ResetPassword from '../pages/ResetPassword'
import Confirm from '../pages/Confirm'
import AccountSettings from '../pages/AccountSettings'
import PocketCallback from "../pages/PocketCallback";
import RequireAuth from '../components/RequireAuth'
import Feeds from '../pages/Feeds'

const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/register" element={<Register />} />
      <Route exact path="/account" element={<RequireAuth><AccountSettings /></RequireAuth>} />
      <Route exact path="/feeds" element={<RequireAuth><Feeds /></RequireAuth>} />
      <Route exact path="/users/confirmation" element={<Confirm />} />
      <Route exact path="/users/password/edit" element={<ResetPassword />} />
      <Route exact path="/passwordreset" element={<ForgotPassword />} />
      <Route exact path="/" element={<RequireAuth><Dashboard /></RequireAuth>} />
      <Route exact path="/pocket/callback" element={<RequireAuth><PocketCallback/></RequireAuth>} />
    </Routes>
  </BrowserRouter >
)

export default Router


// Emails sent by Devise:

// User Confirmation
// http://localhost:3000/users/confirmation?confirmation_token=YEuPrKLWa31sJf6Gg7_y

// PW Reset
//http://localhost:3000/users/password/edit?reset_password_token=Ymaxx-s1XhjPDhvecZyW