import axios from 'axios'

axios.defaults.headers.common['Accept'] = 'application/json';
const BASE_URL = process.env.REACT_APP_API_URL;


class AuthService {

  login(email, password) {
    return axios.post(BASE_URL + "/users/sign_in", { user: { email, password } })
  }

  resetPassword(password, password_confirmation, token) {
    return axios.put(BASE_URL + "/users/password", {user: {
      reset_password_token: token,
      password: password,
      password_confirmation: password_confirmation,
    }});
  }

  confirm(token) {
    return axios.get(BASE_URL + '/users/confirmation', { params: { confirmation_token: token } })
  }

  register(email, password) {
    return axios.post(BASE_URL + '/users', { user: { email, password } })
  }

  logout() {
    localStorage.removeItem("auth")
    localStorage.removeItem("user")
  }

  forgotPassword(email) {
    return axios.post(BASE_URL + '/users/password', { user: { email } })
  }

  isLoggedIn() {
    return !!localStorage.getItem("auth")
  }
}

export default new AuthService();