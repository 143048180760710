import React from "react";
import Navbar from "../components/Navbar";
import Message from "../components/Message";
import AuthService from '../services/AuthService'
import PocketService from '../services/PocketService'

export default class AccountSettings extends React.Component {
  constructor(props) {
    super(props)

    this.connectPocket = this.connectPocket.bind(this)

    this.state = {
      message: {
        text: null,
        type: null
      },
      message_pocket: {
        text: null,
        type: null
      }
    }
  }

  connectPocket(e) {
    console.log('connecting pocket')
    PocketService.connect().then((response) => {
      window.location = response.data.pocket_url
    }).catch(() => {
      this.setState({
        message_pocket: {
          text: "Something went wrong connecting to pocket",
          type: "error",
        },
      });
    })
  }
  
  render() {
    let user = JSON.parse(localStorage.getItem("user"))
    return (
      <>
        {AuthService.isLoggedIn() && <Navbar />}
        <div className="wrap bg-mhblue h-screen">
          <div className="container mx-auto">
            <h1>Account</h1>
            <div className="message mb-3">
              {this.state.message.text && (
                <Message message={this.state.message} />
              )}
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="pareto-card">
                <h2>Integrations</h2>
                <div className="grid grid-cols-2 gap-8 items-center mt-5">
                  <h3>Pocket</h3>
                  <div className="pocket-status flex items-center">
                    {user.has_pocket && (
                      <>
                        <div className="flex gap-4 items-center">
                          <p className="text-green-500">Connected</p>
                          <button className="btn btn-outline-mhorange">
                            Disconnect
                          </button>
                        </div>
                      </>
                    )}
                    {!user.has_pocket && (
                      <button
                        className="btn btn-outline-mhorange"
                        onClick={this.connectPocket}
                      >
                        connect
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
