import AuthService from '../services/AuthService'
import { Navigate } from 'react-router-dom'

const RequireAuth = ({ children }) => {
  if (!AuthService.isLoggedIn()) {
    return <Navigate to="/login" />
  }

  return children
}

export default RequireAuth