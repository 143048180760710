import React from 'react'
import AuthService from '../services/AuthService'
import FeedsService from '../services/FeedsService'
import Navbar from '../components/Navbar'
import FeedFilter from '../components/FeedFilter'
import FeedItems from '../components/FeedItems'

export default class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      feeds: [],
      feeditems: [],
      filter: {
        feed: "*",
        tag: null,
      },
    };

    this.handleFilterClick = this.handleFilterClick.bind(this);
    this.filteredFeeds = this.filteredFeeds.bind(this);
    this.filteredFeedItems = this.filteredFeedItems.bind(this);
    this.handleAddedToPocket = this.handleAddedToPocket.bind(this);
    this.markAllAsRead = this.markAllAsRead.bind(this);
    this.addAllToPocket = this.addAllToPocket.bind(this);
  }

  filteredFeeds(feeds, filter) {
    return feeds.filter((f) => {
      return f.id === parseInt(filter.feed) || filter.feed === "*";
    });
  }

  filteredFeedItems(feeds, filter) {
    let feedsToShow = this.filteredFeeds(feeds, filter);
    return feedsToShow
      .map((f) => f.feeditems.map(fi => ({...fi, feed_title: f.title})))
      .flat()
      .sort((f1, f2) => {
        if (new Date(f1.id) < new Date(f2.id)) {
          return 1;
        } else if (new Date(f1.id) > new Date(f2.id)) {
          return -1;
        }
        return 0;
      });
  }

  handleAddedToPocket(feeditem_id) {
    let feeditems = this.state.feeditems
    let index = feeditems.map((f) => f.id).indexOf(feeditem_id);
    for(let i = 0; i < index; i++) {
      if (feeditems[i].status === "unread") {
        feeditems[i].status = "ignored";
      }
    }
    feeditems[index].status = "todo"
    this.setState({
      feeditems: feeditems
    })
  }

  addAllToPocket(feed_id){
    console.log("Adding all to pocket")
  }

  markAllAsRead() {
    console.log("Marking all as read")
    if(this.state.filter.feed !== "*") {
      FeedsService.markAllAsRead(this.state.filter.feed).then(() => {
        this.refreshAll();
      });
    }
  }

  refreshAll() {
     FeedsService.getFeeds().then((response) => {
       let newFilter = {
         feed: "*"
       }
       let feeds = response.data;
       let feeditems = this.filteredFeedItems(feeds, newFilter) || null;
       this.setState({
         feeditems: feeditems,
         feeds: feeds,
         filter: newFilter
       });
     });
  }

  handleFilterClick(e) {
    // filter Feeditems
    let newfilter = {
      feed: e.target.value,
    };
    let feeditems = this.filteredFeedItems(this.state.feeds, newfilter);
    this.setState({
      feeditems: feeditems,
      filter: newfilter,
    });
  }

  componentDidMount() {
    this.refreshAll()
  }

  render() {
    return (
      <>
        {AuthService.isLoggedIn() && <Navbar />}
        <div className="wrap bg-mhblue h-screen">
          <div className="container mx-auto">
            <div className="">
              <FeedFilter
                feeds={this.state.feeds}
                filter={this.state.filter}
                handleFilterClick={this.handleFilterClick}
              />
            </div>
            <div className="feeditems-container my-5">
              <FeedItems
                feeditems={this.state.feeditems}
                filter={this.state.filter}
                markAllAsRead={this.markAllAsRead}
                addAllToPocket={this.addAllToPocket}
                handleAddedToPocket={this.handleAddedToPocket}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}