import axios from "axios";
import authHeader from "./authHeader";

axios.defaults.headers.common["Accept"] = "application/json";
const BASE_URL = process.env.REACT_APP_API_URL;

class PocketService {
  connect() {
    return axios.get(BASE_URL + "/v1/pocket/connect", { headers: authHeader() });
  }

  callback() {
      return axios.get(BASE_URL + "/v1/pocket/callback", {headers: authHeader()})
  }

  addToPocket(feeditem_id) {
      return axios.post(BASE_URL + "/v1/feeditems/"+feeditem_id+"/add_to_pocket",{}, {
        headers: authHeader()
      });
  }
}

export default new PocketService();
