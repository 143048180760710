import PocketService from "../services/PocketService";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function PocketCallback(props) {
  let navigate = useNavigate();

  useEffect(() => {
    PocketService.callback()
      .then((response) => {
        console.log(response);
        navigate("/account")
      })
      .catch(() => {
        navigate("/account")
      });
  }, [navigate]);

  return (
    <>
      <h1>Pocket Callback</h1>
    </>
  );
}
