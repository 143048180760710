import React from 'react'
import PasswordForgottenForm from '../components/PasswordForgottenForm'

const ForgotPassword = () => {
  return (
    <>
      <div className="container--centered">
        <PasswordForgottenForm>
          <h1 className="text-center">Initiate Password Reset</h1>
        </PasswordForgottenForm>
      </div>
    </>
  )
}

export default ForgotPassword