import React from 'react'
import AuthService from '../services/AuthService'
import LoginRegisterForm from '../components/LoginRegisterForm'

function Register() {
  const handleRegister = (email, password) => {
    return AuthService.register(email, password)
  }

  return (
    <>
      <div className="container--centered">
        <LoginRegisterForm register handleSubmit={handleRegister}>
          <h1 className="text-center">Register</h1>
        </LoginRegisterForm>
      </div>
    </>
  )
}

export default Register