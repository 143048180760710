import React from 'react'
import {Fragment} from 'react'
import AuthService from '../services/AuthService'
import { Link } from 'react-router-dom'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, MenuIcon } from "@heroicons/react/solid";

export default class NavBar extends React.Component {
  handleLogout = (e) => {
    AuthService.logout()
  }

  render() {
    return (
      <>
        <div className="flex justify-between py-2 px-4 border-b border-white">
          <Link to="/">
            <h1 className="m-0 font-bold mr-auto">
              <span className="text-mhorange">pareto</span>
              <span className="text-white">.news</span>
            </h1>
          </Link>
          <div className="flex">
            <Link to="/feeds" className="hidden md:inline-block">
              <button>Feeds</button>
            </Link>
            <Menu as="div" className="navbar relative  text-right">
              <Menu.Button className="inline-flex">
                <span className="hidden md:inline-block">
                  {JSON.parse(localStorage.getItem("user")).email}
                </span>
                <ChevronDownIcon
                  className="-mr-1 ml-2 h-5 w-5 hidden md:inline-block"
                  aria-hidden="true"
                />
                <MenuIcon className="-mr-1 ml-2 h-5 w-5 md:hidden" aria-hidden="true" />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="z-50 ring-1 ring-white bg-mhblue origin-top-right absolute right-0 p-4 flex flex-col gap-4">
                  <Menu.Item className="md:hidden">
                    <Link to="/feeds">
                      Feeds
                    </Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/account">Account</Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/login" onClick={this.handleLogout}>
                      Logout
                    </Link>
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </>
    );
  }
}