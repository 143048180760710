import React from 'react'
import Router from './components/Router'
import './App.scss'

class App extends React.Component {
  render() {
    return (
      <>
        <Router />
      </>
    )
  }
}

export default App;

