import React from 'react'
import PasswordResetForm from '../components/PasswordResetForm'
import { useSearchParams } from 'react-router-dom'


export default function ResetPassword({ props }) {
  const [params] = useSearchParams()
  const token = params.get("reset_password_token")

  return (
    <>
      <div className="container--centered">
        <PasswordResetForm token={token}>
          <h1>Reset Password</h1>
        </PasswordResetForm>
      </div>
    </>
  );
}