import React from 'react'
import { Link, Navigate } from 'react-router-dom'
import AuthService from '../services/AuthService'
import Message from './Message'

export default class LoginRegisterForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = { email: '', password: '', message: null, errors: [] }

    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  errorsToState(errors) {
    console.log("writing errors to state")
    console.log(errors)
    let errorState = {}
    Object.entries(errors).forEach(([key,v]) => {
      // for (let key in error.detail) {
      errorState[key] = key.charAt(0).toUpperCase() + key.slice(1) + ' ' + v.join('\n')
      // }
    })

    return errorState
  }

  handleSubmit(event) {
    this.setState({errors: []})
    event.preventDefault()
    this.props.handleSubmit(this.state.email, this.state.password)
      .then(response => {
        if (response.headers.authorization) {
          localStorage.setItem("auth", response.headers.authorization);
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        this.setState({
          message: { type: 'success', text: response.data.message}
        })
      }).catch(error => {
        if (error.response.data.errors) {
          this.setState({ errors: this.errorsToState(error.response.data.errors) })
        } else {
          this.setState({
            message: { text: error.response.data.error, type: 'error' }
          })
        }
      })
  }

  handleChange(event) {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({ [name]: value })
  }

  render() {
    if (AuthService.isLoggedIn()) {
      return <Navigate to="/" />
    }
    return (
      <>
        <div className="pareto-card">
          {this.props.children}

          {this.state.message != null && (
            <div className="mb-3">
              <Message message={this.state.message} showBack={true} />
            </div>
          )}
          {(this.state.message == null ||
            this.state.message.type === "error") && (
            <form onSubmit={this.handleSubmit} className="flex flex-col">
              <input
                type="email"
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
                className={`${this.state.errors["email"] ? "has-error" : ""}`}
              />
              <span className="text-red-500">{this.state.errors["email"]}</span>
              <input
                type="password"
                name="password"
                value={this.state.password}
                onChange={this.handleChange}
                className={` mb-0${
                  this.state.errors["password"] ? "has-error" : ""
                }`}
              />
              <span className="text-red-500">
                {this.state.errors["password"]}
              </span>

              <button className="btn-mhorange mt-3 mb-3">
                {this.props.login && "Login"}
                {this.props.register && "Register"}
              </button>
              {this.props.login && (
                <Link to="/register">Don't have an account yet?</Link>
              )}
              {this.props.register && (
                <Link to="/login">Already have an account?</Link>
              )}
              <Link to="/passwordreset">Forgot password?</Link>
            </form>
          )}
        </div>
      </>
    );
  }
}