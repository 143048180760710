import React, { useEffect, useState } from 'react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import AuthService from '../services/AuthService'
import Message from '../components/Message'

const Confirm = ({ props }) => {
  const [params] = useSearchParams()
  const [message, setMessage] = useState(null)
  let navigate = useNavigate()

  useEffect(() => {
    AuthService.confirm(params.get('confirmation_token')).then(response => {
      debugger
      navigate('/login')
    }).catch(error => {

      if (error.response.data.confirmation_token[0] === 'was already confirmed, please try signing in') {
        // TODO: make this cleaner
        navigate('/login')
      }
      setMessage({type: 'error', text: "Confirmation token " + error.response.data.confirmation_token.join(', ')})
    })
  }, [navigate, params])



  return (
    <>
      <div className="container--centered">
        <div className="pareto-card text-center">
          <h1 className="text-center">Confirmation</h1>
          <Message message={message}/>
        </div>
      </div>
    </>
  );
}

export default Confirm