
import { TrashIcon } from '@heroicons/react/solid'

export default function Feed({feed}) {
    return (
      <article key={feed.id} className="bg-mhgrey ring-2 ring-white p-4">
        <div className="flex justify-between border-b">
          <h2 className="mr-4">{feed.title}</h2>
          <div>
              <TrashIcon className="h-5 w-5 text-mhorange" />
          </div> 
        </div>
        <p>{feed.url}</p>
        <p>{feed.desc}</p>
      </article>
    );
}