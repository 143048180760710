import React from 'react'
import AuthService from '../services/AuthService'
import Message from './Message'
import {Link, Navigate} from 'react-router-dom'

class PasswordResetForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      password_confirmation: "",
      message: null,
      errors: [],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  errorsToState(errors) {
    console.log("writing errors to state");
    console.log(errors);
    let errorState = {};
    Object.entries(errors).forEach(([key, v]) => {
      // for (let key in error.detail) {
      errorState[key] =
        key.charAt(0).toUpperCase() + key.slice(1) + " " + v.join("\n");
      // }
    });

    return errorState;
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      errors: []
    })

    AuthService.resetPassword(
      this.state.password,
      this.state.password_confirmation,
      this.props.token
    )
      .then((response) => {
        console.log(response);
        this.setState({
          message: { type: "success", text: response.data.message },
        });
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data.errors["reset_password_token"]) {
          this.setState({
            message: {
              type: "error",
              text: "Password reset token is invalid or expired.",
            },
          });
        }
        this.setState({
          errors: this.errorsToState(error.response.data.errors),
        });
        
      });
  }

  handleChange(e) {
    console.log(e.target.name);
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  render() {
    if(this.state.message && this.state.message.type === 'success') {
      return <Navigate to="/login" />
    }
    return (
      <>
        <div className="container--centered">
          <div className="pareto-card">
            {this.props.children}
            {this.state.message && (
              <div className="mb-3">
                <Message message={this.state.message} />
              </div>
            )}
            <form onSubmit={this.handleSubmit} className="flex flex-col">
              <input
                type="password"
                name="password"
                value={this.state.password}
                onChange={this.handleChange}
                className={`${
                  this.state.errors["password"] ? "has-error" : ""
                }`}
              />
              <span className="text-red-500">
                {this.state.errors["password"]}
              </span>
              <input
                type="password"
                name="password_confirmation"
                value={this.state.password_confirmation}
                onChange={this.handleChange}
                className={`${
                  this.state.errors["password_confirmation"] ? "has-error" : ""
                }`}
              />
              <span className="text-red-500">
                {this.state.errors["password_confirmation"]}
              </span>
              <button className="btn-mhorange mt-3 mb-3">Reset Password</button>
              <Link to="/register">Don't have an account yet?</Link>
              <Link to="/login">Back to Login</Link>
            </form>
          </div>
        </div>
      </>
    );
  }
}

export default PasswordResetForm