import React from "react";
import FeedItem from "./FeedItem";

export default class FeedItems extends React.Component {


  render() {
    return (
      <main>
        <div className="flex w-100 justify-center gap-4 mb-8">
          {this.props.filter.feed !== "*" && (
            <>
              <button
                className="btn btn-outline-white"
                onClick={this.props.markAllAsRead}
              >
                Mark all as read
              </button>
              {/* <button className="btn btn-outline-mhorange" onClick={this.props.addAllToPocket}>Add all to Pocket</button> */}
            </>
          )}
        </div>
        {this.props.feeditems &&
          this.props.feeditems.map((f) => (
            <FeedItem
              key={f.id}
              item={f}
              handleAddedToPocket={this.props.handleAddedToPocket}
            />
          ))}
      </main>
    );
  }
}
