import React from 'react'
import { Link } from 'react-router-dom'
import AuthService from '../services/AuthService'
import Message from './Message'

class PasswordForgottenForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: '',
      errors: {},
      message: null
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleSubmit(e) {
    e.preventDefault()
    AuthService.forgotPassword(this.state.email)
      .then(response => {
        console.log(response)
        this.setState({message: {type: 'success', text: response.data.message}})
      })
      .catch(error => {
        console.log(error)
         this.setState({
           message: { type: "success", text: error.data.message },
         });
      })
  }

  handleChange(e) {
    this.setState({
      email: e.target.value
    })
  }

  render() {
    return (
      <>
        <div className="pareto-card">
          {this.props.children}
          {!this.state.message && (
            <form onSubmit={this.handleSubmit} className="flex flex-col">
              <input type="email" name="email" value={this.state.email} onChange={this.handleChange} className={`${this.state.errors['email'] ? 'has-error' : ''}`} />
              <span className="text-red-500">{this.state.errors['email']}</span>
              <button className="btn-mhorange mt-3 mb-3">Send Password Reset Instructions</button>
              <Link to="/register">Don't have an account yet?</Link>
              <Link to="/login">Back to Login</Link>
            </form>
          )}
          {this.state.message && (
            <Message message={this.state.message} />
          )}
        </div >
      </>
    )
  }
}

export default PasswordForgottenForm