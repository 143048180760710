import React from 'react'
import AuthService from '../services/AuthService'
import LoginRegisterForm from '../components/LoginRegisterForm'

function Login() {

  const handleLogin = (email, password) => {
    return AuthService.login(email, password)
  }

  return (
    <>
      <div className="container--centered">
        <LoginRegisterForm login handleSubmit={handleLogin}>
          <h1 className="text-center">Login</h1>
        </LoginRegisterForm>
      </div>
    </>
  )
}

export default Login