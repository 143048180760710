import React from 'react'

export default function Message({simple, message}){
    return (
      <>
        <div className="text-center">
          {simple && (
            <p>{message.text}</p>
          )}
            <div className={`alert alert-${message && message.type} `}>
              <h2>{message && message.text}</h2>
            </div>
            {message && message.showBack === true && (
              <button
                className="btn btn-outline-mhblue mt-3"
                onClick={(e) => {
                  window.location.reload();
                }}
              >
                &lt;- Go back
              </button>
            )}
        </div>
      </>
    );
}