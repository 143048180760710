import axios from 'axios'
import authHeader from './authHeader'

axios.defaults.headers.common['Accept'] = 'application/json';
const BASE_URL = process.env.REACT_APP_API_URL;

class FeedsService {
  getFeeds() {
    return axios.get(BASE_URL + '/v1/feeds', { headers: authHeader() })
  }

  markAllAsRead(feed_id) {
    return axios.post(BASE_URL + "/v1/feeds/" + feed_id + "/mark_all_as_read", {}, {headers: authHeader()})
  }
}

export default new FeedsService()