import AuthService from "../services/AuthService";
import FeedsService from "../services/FeedsService";
import Navbar from "../components/Navbar";
import Feed from "../components/Feed";
import React from "react";

export default class Feeds extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        feeds: null
    }
  }

  componentDidMount() {
    FeedsService.getFeeds().then((response) => {
      console.log(response);
      this.setState({
        feeds: response.data,
      });
    });
  }

  render() {
    return (
      <>
        {AuthService.isLoggedIn() && <Navbar />}
        <div className="wrap bg-mhblue h-screen">
          <div className="container mx-auto">
            <h1>Feeds</h1>
            <div className="grid grid-cols-2 gap-8">
              {this.state.feeds && this.state.feeds.map((feed) => <Feed feed={feed}/>)}
            </div>
          </div>
        </div>
      </>
    );
  }
}
