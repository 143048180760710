import { ChevronRightIcon } from "@heroicons/react/solid";

export default function FeedFilter({ feeds, filter, handleFilterClick }) {
  return (
    <>
      <h2 className="mb-2">Feeds</h2>
      <div className="flex align-items-center relative">
        <div className="feedlist gap-2 flex overflow-x-scroll pr-12">
          <button
            value="*"
            onClick={handleFilterClick}
            className={`pareto-pill ${
              filter.feed === "*" ? "active" : ""
            } `}
          >
            All
          </button>
          {feeds &&
            feeds
              .filter((f) => {
                return f.feeditems.length > 0;
              })
              .map((feed) => (
                <button
                  key={feed.id}
                  value={feed.id}
                  onClick={handleFilterClick}
                  className={`pareto-pill ${
                    parseInt(filter.feed) === feed.id ? "active" : ""
                  } `}
                >
                  {feed.title}
                </button>
              ))}
        </div>
        <div className="w-10 scroll-indicator bg-gradient-to-r from-transparent via-mhblue to-mhblue absolute right-0 text-mhorange">
          <ChevronRightIcon className="-mt-1" />
        </div>
      </div>
    </>
  );
}
